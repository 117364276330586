define("barberscore-web/templates/components/session-rounds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OwEvDY6E",
    "block": "{\"symbols\":[\"round\"],\"statements\":[[7,\"div\"],[11,\"class\",\"table-responsive\"],[9],[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"table table-hover table-sm\"],[9],[0,\"\\n    \"],[7,\"thead\"],[11,\"class\",\"thead-light\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Name\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Status\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"sortedRounds\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1,[\"isLoading\"]]],null,{\"statements\":[[0,\"            \"],[7,\"td\"],[11,\"colspan\",\"2\"],[9],[0,\"Loading \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"td\"],[9],[4,\"link-to\",null,[[\"route\",\"model\"],[\"dashboard.conventions.convention.sessions.session.rounds.round.details\",[24,1,[]]]],{\"statements\":[[1,[24,1,[\"kind\"]],false]],\"parameters\":[]},null],[10],[0,\"\\n            \"],[7,\"td\"],[9],[1,[24,1,[\"status\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"No active Rounds currently assigned to you.\"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/session-rounds.hbs"
    }
  });

  _exports.default = _default;
});