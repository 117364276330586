define("barberscore-web/models/song", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  var _default = _model.default.extend({
    status: _emberData.default.attr('song-status'),
    num: _emberData.default.attr('number'),
    asterisks: _emberData.default.attr(),
    dixons: _emberData.default.attr(),
    penalties: _emberData.default.attr(),
    stats: _emberData.default.attr(),
    chartId: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    arrangers: _emberData.default.attr('string'),
    appearance: _emberData.default.belongsTo('appearance', {
      async: true
    }),
    scores: _emberData.default.hasMany('score', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    isDisabled: Ember.computed.not('permissions.write'),
    practiceScores: Ember.computed.filterBy('scores', 'isPractice'),
    practiceScoresPoints: Ember.computed.mapBy('practiceScores', 'intPoints'),
    sumPracticeScores: Ember.computed.sum('practiceScoresPoints'),
    officialScores: Ember.computed.filterBy('scores', 'isOfficial'),
    officialScoresPoints: Ember.computed.mapBy('officialScores', 'intPoints'),
    sumOfficialScores: Ember.computed.sum('officialScoresPoints'),
    statusOptions: ['New'],
    penaltyOptions: [30, 32, 34, 36, 38, 39, 40, 44, 48, 50]
  });

  _exports.default = _default;
});