define("barberscore-web/components/person-memberships", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortedMembersProperties: ['categorySort', 'kindSort', 'personSort'],
    sortedMembers: Ember.computed.sort('model.members', 'sortedMembersProperties')
  });

  _exports.default = _default;
});