define("barberscore-web/transforms/session-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        0: 'New',
        2: 'Built',
        4: 'Opened',
        8: 'Closed',
        10: 'Verified',
        20: 'Packaged',
        30: 'Finished'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'New': 0,
        'Built': 2,
        'Opened': 4,
        'Closed': 8,
        'Verified': 10,
        'Packaged': 20,
        'Finished': 30
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});