define("barberscore-web/helpers/single-fixed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.singleFixed = singleFixed;
  _exports.default = void 0;

  function singleFixed(params) {
    if (params[0]) {
      return params[0].toFixed(1).toString();
    } else {
      return "N/A";
    }
  }

  var _default = Ember.Helper.helper(singleFixed);

  _exports.default = _default;
});