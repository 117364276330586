define("barberscore-web/transforms/award-gender", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        10: 'Male',
        20: 'Female',
        30: 'Mixed'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Male': 10,
        'Female': 20,
        'Mixed': 30
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});