define("barberscore-web/transforms/award-kind", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        32: 'Chorus',
        41: 'Quartet'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Chorus': 32,
        'Quartet': 41
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});