define("barberscore-web/templates/components/search-conventions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iwac9MQy",
    "block": "{\"symbols\":[\"convention\"],\"statements\":[[7,\"p\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"search\",\"selected\",\"onchange\",\"placeholder\",\"allowClear\",\"renderInPlace\",\"noMatchesMessage\"],[[29,\"perform\",[[25,[\"searchConvention\"]]],null],[25,[\"convention\"]],[29,\"perform\",[[25,[\"redirectConvention\"]]],null],\"Enter Convention Name\",true,true,\"We Could Not Find a Convention with that Name\"]],{\"statements\":[[0,\"    Convention: \"],[1,[24,1,[\"nomen\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/search-conventions.hbs"
    }
  });

  _exports.default = _default;
});