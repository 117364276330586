define("barberscore-web/helpers/is-judge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isJudge = isJudge;
  _exports.default = void 0;

  function isJudge(params) {
    if ((params[0].length > '1' || !params[0].includes('Manager')) && !params[0].includes('BS Admin') && !params[0].includes('CA') && !params[0].includes('PC') && !params[0].includes('ADM')) {
      return true;
    }

    return false;
  }

  var _default = Ember.Helper.helper(isJudge);

  _exports.default = _default;
});