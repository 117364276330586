define("barberscore-web/models/award", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: _emberData.default.attr('string'),
    status: _emberData.default.attr('award-status'),
    kind: _emberData.default.attr('award-kind'),
    gender: _emberData.default.attr('award-gender'),
    level: _emberData.default.attr('award-level'),
    season: _emberData.default.attr('award-season'),
    isSingle: _emberData.default.attr('boolean'),
    threshold: _emberData.default.attr('number'),
    minimum: _emberData.default.attr('number'),
    advance: _emberData.default.attr('number'),
    spots: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    district: _emberData.default.attr('award-district'),
    division: _emberData.default.attr('award-division'),
    age: _emberData.default.attr('award-age'),
    isNovice: _emberData.default.attr('boolean'),
    size: _emberData.default.attr('award-size'),
    sizeRange: _emberData.default.attr(),
    scope: _emberData.default.attr('award-scope'),
    scopeRange: _emberData.default.attr(),
    treeSort: _emberData.default.attr('number'),
    permissions: _emberData.default.attr(),
    activate: (0, _emberApiActions.memberAction)({
      path: 'activate',
      type: 'post'
    }),
    deactivate: (0, _emberApiActions.memberAction)({
      path: 'deactivate',
      type: 'post'
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    statusOptions: ['New', 'Active', 'Inactive'],
    kindOptions: ['Quartet', 'Chorus'],
    generOptions: ['Male', 'Female', 'Mixed'],
    levelOptions: ['Championship', 'Qualifier', 'Representative', 'Deferred', 'Manual', 'Improved - Raw', 'Improved - Standard'],
    ageOptions: ['Seniors', 'Youth'],
    seasonOptions: ['Summer', 'Midwinter', 'Fall', 'Spring'],
    isChampionship: Ember.computed.equal('level', 'Championship'),
    groupParent: Ember.computed.alias('group.parent'),
    groupKindSort: Ember.computed.alias('group.kindSort'),
    groupNameSort: Ember.computed.alias('group.name'),
    statusSort: Ember.computed('status', 'statusOptions', function () {
      return this.statusOptions.indexOf(this.status);
    }),
    kindSort: Ember.computed('kind', 'kindOptions', function () {
      return this.kindOptions.indexOf(this.kind);
    }),
    levelSort: Ember.computed('level', 'levelOptions', function () {
      return this.levelOptions.indexOf(this.level);
    }),
    seasonSort: Ember.computed('season', 'seasonOptions', function () {
      return this.seasonOptions.indexOf(this.season);
    }),
    ageSort: Ember.computed('age', 'ageOptions', function () {
      return this.ageOptions.indexOf(this.age);
    })
  });

  _exports.default = _default;
});