define("barberscore-web/routes/dashboard/groups/group/entries/entry", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(_ref) {
      var entry_id = _ref.entry_id;

      var _this$paramsFor = this.paramsFor('dashboard.groups.group'),
          group_id = _this$paramsFor.group_id;

      return Ember.RSVP.hash({
        entry: this.store.findRecord('entry', entry_id),
        group: this.store.findRecord('group', group_id)
      });
    }
  });

  _exports.default = _default;
});