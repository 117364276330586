define("barberscore-web/models/score", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: _emberData.default.attr('score-status'),
    points: _emberData.default.attr('number'),
    song: _emberData.default.belongsTo('song', {
      async: true
    }),
    panelist: _emberData.default.belongsTo('panelist', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    kind: Ember.computed.alias('panelist.kind'),
    num: Ember.computed.alias('panelist.num'),
    category: Ember.computed.alias('panelist.category'),
    isDisabled: Ember.computed.not('permissions.write'),
    intPoints: Ember.computed('points', function () {
      return parseInt(this.points);
    }),
    isOfficial: Ember.computed.equal('kind', 'Official'),
    isSinging: Ember.computed.equal('category', 'Singing'),
    isOfficialSinging: Ember.computed.and('isOfficial', 'isSinging'),
    isPerformance: Ember.computed.equal('category', 'Performance'),
    isOfficialPerformance: Ember.computed.and('isOfficial', 'isPerformance'),
    isPractice: Ember.computed.equal('kind', 'Practice'),
    statusOptions: ['New', 'Validated', 'Cleared', 'Flagged', 'Revised', 'Confirmed'],
    categoryOptions: ['Music', 'Performance', 'Singing'],
    kindOptions: ['Official', 'Practice', 'Composite'],
    rowClass: Ember.computed('kind', function () {
      if (this.category === 'Music') {
        return 'warning';
      } else if (this.category === 'Performance') {
        return 'success';
      } else if (this.category === 'Singing') {
        return 'info';
      } else {
        return null;
      }
    }),
    panelistName: Ember.computed.alias('panelist.person.lastName'),
    songNum: Ember.computed.alias('song.num'),
    appearanceId: Ember.computed.alias('song.appearance.id')
  });

  _exports.default = _default;
});