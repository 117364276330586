define("barberscore-web/components/group-reports", ["exports", "barberscore-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      clickRoster: function clickRoster() {
        var apiHost = _environment.default.APP.API_HOST;
        var modelId = this.get('model.id');
        var url = "".concat(apiHost, "/api/group/").concat(modelId, "/roster");
        return window.open(url);
      }
    }
  });

  _exports.default = _default;
});