define("barberscore-web/templates/components/search-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3910LT7o",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[7,\"p\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"search\",\"selected\",\"onchange\",\"placeholder\",\"allowClear\",\"renderInPlace\",\"noMatchesMessage\"],[[29,\"perform\",[[25,[\"searchGroup\"]]],null],[25,[\"group\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"group\"]]],null]],null],\"Enter Group Name\",true,true,\"We Could Not Find an Active Group with that Name\"]],{\"statements\":[[0,\"    Group: \"],[1,[29,\"html-safe\",[[24,1,[\"name\"]]],null],false],[0,\" \"],[4,\"if\",[[24,1,[\"parent\",\"shortName\"]]],null,{\"statements\":[[0,\"(\"],[1,[24,1,[\"parent\",\"shortName\"]],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  \"],[1,[29,\"html-safe\",[[25,[\"group\",\"name\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/search-groups.hbs"
    }
  });

  _exports.default = _default;
});