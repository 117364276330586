define("barberscore-web/transforms/award-age", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        10: 'Seniors',
        30: 'Youth'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Seniors': 10,
        'Youth': 30
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});