define("barberscore-web/templates/components/download-legacy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pUEUAUpm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"success\",[29,\"perform\",[[25,[\"legacy\"]]],null]]],{\"statements\":[[4,\"if\",[[25,[\"legacy\",\"isRunning\"]]],null,{\"statements\":[[0,\"    Downloading \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"legacy\",\"last\",\"isError\"]]],null,{\"statements\":[[0,\"    Error Downloading\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Download Legacy Report  \"],[7,\"i\"],[11,\"class\",\"fa fa-file-excel-o\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/download-legacy.hbs"
    }
  });

  _exports.default = _default;
});