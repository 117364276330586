define("barberscore-web/initializers/inflector", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    var inflector = _emberInflector.default.inflector;
    inflector.uncountable('appearance');
    inflector.uncountable('assignment');
    inflector.uncountable('award');
    inflector.uncountable('chart');
    inflector.uncountable('contest');
    inflector.uncountable('convention');
    inflector.uncountable('entry');
    inflector.uncountable('group');
    inflector.uncountable('outcome');
    inflector.uncountable('panelist');
    inflector.uncountable('person');
    inflector.uncountable('repertory');
    inflector.uncountable('role');
    inflector.uncountable('round');
    inflector.uncountable('score');
    inflector.uncountable('session');
    inflector.uncountable('song');
    inflector.uncountable('statelog');
    inflector.uncountable('user');
  }

  var _default = {
    name: 'inflector',
    initialize: initialize
  };
  _exports.default = _default;
});