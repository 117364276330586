define("barberscore-web/helpers/map-penalty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fooBar = fooBar;
  _exports.default = void 0;

  function fooBar(params) {
    if (params[0]) {
      var penaltyMap = {
        30: 'Repeating Substantial Portions of a Song',
        32: 'Instrumental Accompaniment',
        34: 'Chorus Exceeding 4-Part Texture',
        36: 'Excessive Melody Not in Inner Part',
        38: 'Lack of Characteristic Chord Progression',
        39: 'Excessive Lyrics < 4 parts',
        40: 'Primarily Patriotic/Religious Intent',
        44: 'Not in Good Taste',
        48: 'Non-members Performing on Stage',
        50: 'Sound Equipment or Electronic Enhancement'
      };
      return penaltyMap[params[0]];
    }
  }

  var _default = Ember.Helper.helper(fooBar);

  _exports.default = _default;
});