define("barberscore-web/templates/components/prev-next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j7oHZC8a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"float-right\"],[9],[0,\"\\n\"],[4,\"bs-button-group\",null,[[\"type\"],[\"radio\"]],{\"statements\":[[4,\"bs-button\",null,[[\"type\",\"disabled\",\"onClick\"],[\"outline-dark\",[29,\"not\",[[29,\"has-previous\",[[25,[\"model\"]],[25,[\"sortedArray\"]]],null]],null],[29,\"action\",[[24,0,[]],\"trans\",[29,\"previous\",[[25,[\"model\"]],[25,[\"sortedArray\"]]],null]],null]]],{\"statements\":[[0,\"       Previous\\n\"]],\"parameters\":[]},null],[4,\"bs-button\",null,[[\"type\",\"disabled\",\"onClick\"],[\"outline-dark\",[29,\"not\",[[29,\"has-next\",[[25,[\"model\"]],[25,[\"sortedArray\"]]],null]],null],[29,\"action\",[[24,0,[]],\"trans\",[29,\"next\",[[25,[\"model\"]],[25,[\"sortedArray\"]]],null]],null]]],{\"statements\":[[0,\"        Next\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/prev-next.hbs"
    }
  });

  _exports.default = _default;
});