define("barberscore-web/transforms/panelist-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        5: 'DRCJ',
        9: 'PC',
        10: 'ADM',
        30: 'Music',
        40: 'Performance',
        50: 'Singing'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'DRCJ': 5,
        'PC': 9,
        'ADM': 10,
        'Music': 30,
        'Performance': 40,
        'Singing': 50
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});