define("barberscore-web/transforms/award-season", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        1: 'Summer',
        2: 'Midwinter',
        3: 'Fall',
        4: 'Spring',
        9: 'Video'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Summer': 1,
        'Midwinter': 2,
        'Fall': 3,
        'Spring': 4,
        'Video': 9
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});