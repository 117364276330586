define("barberscore-web/templates/dashboard/conventions/convention/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eGPQQAmB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"roundsFinalized\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[11,\"class\",\"list-unstyled\"],[9],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"double\"],[9],[1,[29,\"download-bbstix\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[10],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"double\"],[9],[1,[29,\"download-bbstix-practice\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"\\n      BBSTIX reports are available after all Rounds have reached a status of \\\"Finalized\\\" or \\\"Published\\\".\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/dashboard/conventions/convention/reports.hbs"
    }
  });

  _exports.default = _default;
});