define("barberscore-web/helpers/is-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isBsAdmin = isBsAdmin;
  _exports.default = void 0;

  function isBsAdmin(params) {
    if (params[0].includes('BS Admin') || params[0].includes('CA') || params[0].includes('PC') || params[0].includes('ADM')) {
      return true;
    }

    return false;
  }

  var _default = Ember.Helper.helper(isBsAdmin);

  _exports.default = _default;
});