define("barberscore-web/components/session-rounds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortedRoundsProperties: ['num'],
    sortedRounds: Ember.computed.sort('model', 'sortedRoundsProperties')
  });

  _exports.default = _default;
});