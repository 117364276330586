define("barberscore-web/controllers/faq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    faq1: true,
    faq2: true,
    faq3: true,
    faq4: true,
    faq5: true,
    faq6: true,
    faq7: true,
    faq8: true,
    faq9: true,
    faq10: true,
    faq11: true,
    faq11a: true,
    faq12: true,
    faq13: true,
    faq14: true,
    faq15: true,
    faq16: true,
    faq16a: true,
    faq17: true,
    faq18: true,
    faq19: true
  });

  _exports.default = _default;
});