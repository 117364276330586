define("barberscore-web/components/officer-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    collapsedNote: true,
    sortedOfficersProperties: ['groupSort', 'groupName'],
    sortedOfficers: Ember.computed.sort('model', 'sortedOfficersProperties')
  });

  _exports.default = _default;
});