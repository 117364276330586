define("barberscore-web/models/contest", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: _emberData.default.attr('contest-status'),
    awardId: _emberData.default.belongsTo('award', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    kind: _emberData.default.attr('award-kind'),
    gender: _emberData.default.attr('award-gender'),
    level: _emberData.default.attr('award-level'),
    season: _emberData.default.attr('award-season'),
    description: _emberData.default.attr('string'),
    district: _emberData.default.attr('string'),
    division: _emberData.default.attr('award-division'),
    age: _emberData.default.attr('award-age'),
    isNovice: _emberData.default.attr('boolean'),
    isSingle: _emberData.default.attr('boolean'),
    size: _emberData.default.attr('award-size'),
    sizeRange: _emberData.default.attr(),
    scope: _emberData.default.attr('award-scope'),
    scopeRange: _emberData.default.attr(),
    treeSort: _emberData.default.attr('number'),
    session: _emberData.default.belongsTo('session', {
      async: true
    }),
    entries: _emberData.default.hasMany('entry', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    include: (0, _emberApiActions.memberAction)({
      path: 'include',
      type: 'post'
    }),
    exclude: (0, _emberApiActions.memberAction)({
      path: 'exclude',
      type: 'post'
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    sessionStatus: Ember.computed.alias('session.status'),
    isSessionNew: Ember.computed.equal('sessionStatus', 'New'),
    isSessionBuilt: Ember.computed.equal('sessionStatus', 'Built'),
    isSessionNewBuilt: Ember.computed.or('isSessionNew', 'isSessionBuilt'),
    isCheckboxDisabled: Ember.computed.not('isSessionNewBuilt'),
    statusOptions: ['New', 'Included', 'Excluded'],
    kindOptions: ['New', 'Championship', 'Qualifier'],
    isIncluded: Ember.computed.equal('status', 'Included'),
    isAwardQualifier: Ember.computed.equal('level', 'Qualifier'),
    notQualifier: Ember.computed.not('isAwardQualifier') // entriesCount: alias('entries.length'),

  });

  _exports.default = _default;
});