define("barberscore-web/components/session-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortProperties: ['statusSort:asc', 'groupKindSort:asc'],
    sortedSessions: Ember.computed.sort('model', 'sortProperties')
  });

  _exports.default = _default;
});