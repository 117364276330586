define("barberscore-web/templates/components/download-sa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fNVTwpI2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"success\",[29,\"perform\",[[25,[\"sa\"]]],null]]],{\"statements\":[[4,\"if\",[[25,[\"sa\",\"isRunning\"]]],null,{\"statements\":[[0,\"    Downloading \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"sa\",\"last\",\"isError\"]]],null,{\"statements\":[[0,\"    Error Downloading\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"model\",\"status\"]],\"Completed\"],null]],null,{\"statements\":[[0,\"      Download \"],[7,\"em\"],[9],[0,\"Draft\"],[10],[0,\" SA  \"],[7,\"i\"],[11,\"class\",\"fa fa-file-pdf-o\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Download \"],[7,\"strong\"],[9],[0,\"Final\"],[10],[0,\" SA  \"],[7,\"i\"],[11,\"class\",\"fa fa-file-pdf-o\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/download-sa.hbs"
    }
  });

  _exports.default = _default;
});