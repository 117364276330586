define("barberscore-web/transforms/entry-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        0: 'New',
        2: 'Built',
        5: 'Invited',
        7: 'Withdrawn',
        10: 'Submitted',
        20: 'Approved'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'New': 0,
        'Built': 2,
        'Invited': 5,
        'Withdrawn': 7,
        'Submitted': 10,
        'Approved': 20
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});