define("barberscore-web/models/entry", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: _emberData.default.attr('entry-status'),
    isEvaluation: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isPrivate: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isMt: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    draw: _emberData.default.attr('number'),
    seed: _emberData.default.attr('number'),
    prelim: _emberData.default.attr('number'),
    base: _emberData.default.attr('number'),
    participants: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    pos: _emberData.default.attr('number'),
    area: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    chapters: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    description: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    notes: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    imageId: _emberData.default.attr('string', {
      defaultValue: 'missing_image'
    }),
    groupId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    kind: _emberData.default.attr('group-kind'),
    gender: _emberData.default.attr('group-gender'),
    district: _emberData.default.attr('group-district'),
    division: _emberData.default.attr('group-division'),
    bhsId: _emberData.default.attr('number'),
    code: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    isSenior: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isYouth: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    session: _emberData.default.belongsTo('session', {
      async: true
    }),
    contests: _emberData.default.hasMany('contest', {
      async: true
    }),
    repertories: _emberData.default.hasMany('repertory', {
      async: true
    }),
    owners: _emberData.default.hasMany('user', {
      async: true
    }),
    notificationList: _emberData.default.attr('string'),
    statelogs: _emberData.default.hasMany('statelog', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    build: (0, _emberApiActions.memberAction)({
      path: 'build',
      type: 'post'
    }),
    create_manual_entry: (0, _emberApiActions.memberAction)({
      path: 'create_manual_entry',
      type: 'post'
    }),
    invite: (0, _emberApiActions.memberAction)({
      path: 'invite',
      type: 'post'
    }),
    withdraw: (0, _emberApiActions.memberAction)({
      path: 'withdraw',
      type: 'post'
    }),
    submit: (0, _emberApiActions.memberAction)({
      path: 'submit',
      type: 'post'
    }),
    approve: (0, _emberApiActions.memberAction)({
      path: 'approve',
      type: 'post'
    }),
    update_charts: (0, _emberApiActions.memberAction)({
      path: 'update_charts',
      type: 'post'
    }),
    contest: (0, _emberApiActions.memberAction)({
      path: 'contest',
      type: 'post'
    }),
    isDisabled: Ember.computed('permissions.write', 'session.(roundsPublished,status}', function () {
      if (this.get('session.status') != 'Packaged') {
        return true;
      }

      if (this.get('session.roundsPublished')) {
        return true;
      }

      if (this.get('permissions.write')) {
        return false;
      } else {
        return true;
      }
    }),
    notMt: Ember.computed.not('isMt'),
    // groupChartsCount: alias(
    //   'groupCharts.length'
    // ),
    // contestsCount: alias(
    //   'contests.length'
    // ),
    statusOptions: ['New', 'Built', 'Invited', 'Withdrawn', 'Submitted', 'Approved'],
    statusSort: Ember.computed('status', 'statusOptions', function () {
      return this.statusOptions.indexOf(this.status);
    })
  });

  _exports.default = _default;
});