define("barberscore-web/utils/create-session-data-object", ["exports", "ember-simple-auth-auth0/utils/create-session-data-object"], function (_exports, _createSessionDataObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _createSessionDataObject.default;
    }
  });
});