define("barberscore-web/transforms/chart-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        '-20': 'Protected',
        '-10': 'Inactive',
        '0': 'New',
        '10': 'Active'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Protected': '-20',
        'New': '0',
        'Active': '10',
        'Inactive': '-10'
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});