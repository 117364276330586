define("barberscore-web/templates/components/person-memberships", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Txj/Y40",
    "block": "{\"symbols\":[\"member\"],\"statements\":[[7,\"div\"],[11,\"class\",\"table-responsive\"],[9],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"\\n    Your Memberships\\n  \"],[10],[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"table table-hover table-sm\"],[9],[0,\"\\n    \"],[7,\"caption\"],[9],[0,\"These are your memberships.\"],[10],[0,\"\\n    \"],[7,\"thead\"],[11,\"class\",\"thead-light\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Group\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Subscription\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Status\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"sortedMembers\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"\\n            \"],[1,[24,1,[\"group\",\"name\"]],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"\\n            \"],[1,[24,1,[\"subscription\",\"name\"]],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"\\n            \"],[1,[24,1,[\"status\"]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"(You do not have any current memberships. \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/person-memberships.hbs"
    }
  });

  _exports.default = _default;
});