define("barberscore-web/controllers/dashboard/conventions/convention/sessions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortedSessionsProperties: ['kind:desc'],
    sortedSessions: Ember.computed.sort('model', 'sortedSessionsProperties')
  });

  _exports.default = _default;
});