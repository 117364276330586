define("barberscore-web/transforms/contestant-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        '-10': 'Excluded',
        '0': 'New',
        '10': 'Included'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Excluded': '-10',
        'New': '0',
        'Included': '10'
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});