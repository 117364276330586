define("barberscore-web/templates/components/round-footnotes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KV0rznNi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n  \"],[7,\"textarea\"],[12,\"value\",[25,[\"model\",\"footnotes\"]]],[12,\"disabled\",[23,\"isDisabled\"]],[12,\"oninput\",[29,\"perform\",[[25,[\"autosave\"]]],[[\"value\"],[\"target.value\"]]]],[11,\"class\",\"form-control\"],[11,\"rows\",\"3\"],[11,\"placeholder\",\"Enter any footnotes associated with the Round.  Will print on the OSS.\"],[9],[0,\"  \"],[10],[0,\"\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/round-footnotes.hbs"
    }
  });

  _exports.default = _default;
});