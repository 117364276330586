define("barberscore-web/models/repertory", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: _emberData.default.attr('repertory-status'),
    chart_id: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    arrangers: _emberData.default.attr('string'),
    entry: _emberData.default.belongsTo('entry', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    activate: (0, _emberApiActions.memberAction)({
      path: 'activate',
      type: 'post'
    }),
    deactivate: (0, _emberApiActions.memberAction)({
      path: 'deactivate',
      type: 'post'
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    statusOptions: ['New', 'Active', 'Inactive']
  });

  _exports.default = _default;
});