define("barberscore-web/models/person", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: _emberData.default.attr('person-status'),
    name: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    part: _emberData.default.attr('person-part'),
    gender: _emberData.default.attr('person-gender'),
    district: _emberData.default.attr('person-district'),
    email: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    address: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    homePhone: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    workPhone: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    cellPhone: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    airports: _emberData.default.attr(),
    description: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    notes: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    bhsId: _emberData.default.attr('number'),
    sourceId: _emberData.default.attr('string'),
    nomen: _emberData.default.attr('string'),
    imageId: _emberData.default.attr('string'),
    conventions: _emberData.default.hasMany('convention', {
      async: true
    }),
    owners: _emberData.default.hasMany('user', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    activate: (0, _emberApiActions.memberAction)({
      path: 'activate',
      type: 'post'
    }),
    deactivate: (0, _emberApiActions.memberAction)({
      path: 'deactivate',
      type: 'post'
    }),
    isDisabled: Ember.computed.not('permissions.write')
  });

  _exports.default = _default;
});