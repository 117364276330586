define("barberscore-web/templates/components/group-reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7MSbEssZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"list-styled\"],[9],[0,\"\\n        \"],[7,\"li\"],[9],[0,\"Group Roster Excel Report: \"],[7,\"i\"],[11,\"class\",\"fa fa-2x fa-file-excel-o\"],[11,\"aria-hidden\",\"true\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"clickRoster\"],null]],[9],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/group-reports.hbs"
    }
  });

  _exports.default = _default;
});