define("barberscore-web/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    faq1: true,
    faq2: true,
    faq3: true,
    faq4: true,
    faq5: true,
    actions: {
      login: function login() {
        var authOptions = {
          scope: 'openid profile email',
          responseType: 'token id_token'
        };
        this.session.authenticate('authenticator:auth0-universal', authOptions);
      }
    }
  });

  _exports.default = _default;
});