define("barberscore-web/authenticators/auth0-universal", ["exports", "ember-simple-auth-auth0/authenticators/auth0-universal"], function (_exports, _auth0Universal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _auth0Universal.default;
    }
  });
});