define("barberscore-web/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w+8tDbE8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[9],[0,\"\\n  Sorry, something went wrong.\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/error.hbs"
    }
  });

  _exports.default = _default;
});