define("barberscore-web/transforms/convention-panel", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        1: 'Single',
        2: 'Double',
        3: 'Triple',
        4: 'Quadruple',
        5: 'Quintuple'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Single': 1,
        'Double': 2,
        'Triple': 3,
        'Quadruple': 4,
        'Quintuple': 5
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});