define("barberscore-web/templates/components/appearance-start", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EAkI4Eds",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-button\",null,[[\"disabled\",\"type\",\"onClick\"],[[25,[\"model\",\"isDisabled\"]],\"primary\",[29,\"perform\",[[25,[\"startAppearance\"]]],null]]],{\"statements\":[[4,\"if\",[[25,[\"startAppearance\",\"isRunning\"]]],null,{\"statements\":[[0,\"    Starting \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Start Performance\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/appearance-start.hbs"
    }
  });

  _exports.default = _default;
});