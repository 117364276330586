define("barberscore-web/routes/dashboard/conventions/convention/sessions/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    model: function model() {
      var _this$paramsFor = this.paramsFor('dashboard.conventions.convention'),
          convention_id = _this$paramsFor.convention_id;

      return this.store.query('session', {
        filter: {
          'convention_id': convention_id
        }
      });
    }
  });

  _exports.default = _default;
});