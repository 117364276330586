define("barberscore-web/routes/dashboard/conventions/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    model: function model() {
      return this.store.query('convention', {
        filter: {
          'status': 10,
          'owners': this.currentUser.user.id
        }
      });
    }
  });

  _exports.default = _default;
});