define("barberscore-web/helpers/safe-cloudinary-url", ["exports", "ember-cli-cloudinary-light/helpers/safe-cloudinary-url"], function (_exports, _safeCloudinaryUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _safeCloudinaryUrl.default;
    }
  });
  Object.defineProperty(_exports, "safeCloudinaryUrl", {
    enumerable: true,
    get: function get() {
      return _safeCloudinaryUrl.safeCloudinaryUrl;
    }
  });
});