define("barberscore-web/controllers/dashboard/index", ["exports", "barberscore-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { intersect } from '@ember/object/computed';
  var _default = Ember.Controller.extend({
    collapsedNote: true,
    groupRoles: ['Manager'],
    // groupIntersect: intersect(
    //   'model.roles',
    //   'groupRoles',
    // ),
    judgeRoles: ['SCJC', 'DRCJ', 'CA', 'ADM', 'PC'],
    // judgeIntersect: intersect(
    //   'model.roles',
    //   'judgeRoles'
    // ),
    groupsDisabled: false,
    assignmentsDisabled: false,
    // groupsDisabled: empty(
    //   'groupIntersect',
    // ),
    // assignmentsDisabled: empty(
    //   'judgeIntersect',
    // ),
    managerEnabled: _environment.default.APP.managerEnabled
  });

  _exports.default = _default;
});