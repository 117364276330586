define("barberscore-web/components/prev-next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    actions: {
      trans: function trans(model) {
        this.router.transitionTo(this.currentRouteName, model.get('id'));
      }
    }
  });

  _exports.default = _default;
});