define("barberscore-web/models/panelist", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: _emberData.default.attr('panelist-status'),
    num: _emberData.default.attr('number'),
    kind: _emberData.default.attr('panelist-kind'),
    category: _emberData.default.attr('panelist-category'),
    psaReport: _emberData.default.attr('string'),
    area: _emberData.default.attr('string'),
    round: _emberData.default.belongsTo('round', {
      async: true
    }),
    owners: _emberData.default.hasMany('user', {
      async: true
    }),
    personId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    district: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    cellPhone: _emberData.default.attr('string'),
    airports: _emberData.default.attr('string'),
    scores: _emberData.default.hasMany('score', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    psa: (0, _emberApiActions.memberAction)({
      path: 'psa',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    rowClass: Ember.computed('kind', function () {
      if (this.category === 'Music') {
        return 'table-warning score-height';
      } else if (this.category === 'Performance') {
        return 'table-success score-height';
      } else if (this.category === 'Singing') {
        return 'table-primary score-height';
      } else {
        return null;
      }
    }),
    categorySort: Ember.computed('category', 'categoryOptions', function () {
      return this.categoryOptions.indexOf(this.category);
    }),
    isScoring: Ember.computed('category', function () {
      return ['Singing', 'Music', 'Performance'].includes(this.category);
    }),
    kindSort: Ember.computed('kind', 'kindOptions', function () {
      return this.kindOptions.indexOf(this.kind);
    }),
    statusOptions: ['New', 'Active', 'Inactive'],
    categoryOptions: ['DRCJ', 'PC', 'ADM', 'Music', 'Performance', 'Singing'],
    kindOptions: ['Official', 'Practice', 'Observer']
  });

  _exports.default = _default;
});