define("barberscore-web/routes/dashboard/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { isEmpty } from '@ember/utils';
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    userRoles: function userRoles() {
      var profile = this.get('session.data.authenticated.profile');
      var roles = profile['https://www.barberscore.com/roles'];
      return roles;
    },
    model: function model() {
      return Ember.RSVP.hash({
        currentUser: this.get('currentUser.user'),
        userRoles: this.userRoles()
      });
    }
  });

  _exports.default = _default;
});