define("barberscore-web/components/group-officers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDisabled: Ember.computed.not('model.permissions.write'),
    sortedOfficersProperties: ['personLast', 'personName'],
    sortedOfficers: Ember.computed.sort('model.officers', 'sortedOfficersProperties')
  });

  _exports.default = _default;
});