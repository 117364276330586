define("barberscore-web/components/session-contest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortedEntriesProperties: ['groupName:asc'],
    sortedEntries: Ember.computed.sort('model.entries', 'sortedEntriesProperties'),
    sortedContestsProperties: ['isPrimary:desc', 'groupKindSort', 'awardQualifier', 'awardAgeSort', 'awardName'],
    sortedContests: Ember.computed.sort('model.session.contests', 'sortedContestsProperties')
  });

  _exports.default = _default;
});