define("barberscore-web/routes/dashboard/groups/group", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(_ref) {
      var group_id = _ref.group_id;
      return this.store.findRecord('group', group_id, {
        include: 'charts'
      });
    }
  });

  _exports.default = _default;
});