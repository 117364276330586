define("barberscore-web/transforms/assignment-kind", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        10: 'Official',
        20: 'Practice',
        30: 'Observer'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Official': 10,
        'Practice': 20,
        'Observer': 30
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});