define("barberscore-web/routes/dashboard/groups/group/entries/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var group = this.modelFor('dashboard.groups.group');
      var group_id = group.get('id');
      var kind = group.get('kind');
      var kindMap = {
        'Chorus': 32,
        'VLQ': 32,
        'Quartet': 41
      };
      return Ember.RSVP.hash({
        group: group,
        entries: this.store.query('entry', {
          'filter': {
            'group_id': group_id
          }
        }),
        sessions: this.store.query('session', {
          'filter': {
            'status': 4,
            // Open Session; hard-coded
            'is_invitational': false,
            'kind': kindMap[kind]
          }
        })
      });
    }
  });

  _exports.default = _default;
});