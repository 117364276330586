define("barberscore-web/helpers/barberscore-charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.barberscoreSupport = barberscoreSupport;
  _exports.default = void 0;

  function barberscoreSupport() {
    return Ember.String.htmlSafe("<a href='mailto:charts@barberscore.com'>charts@barberscore.com</a>");
  }

  var _default = Ember.Helper.helper(barberscoreSupport);

  _exports.default = _default;
});