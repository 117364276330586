define("barberscore-web/templates/components/round-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Xbq0Xzr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n  Round Date: \"],[0,\"\\n    \"],[1,[29,\"bs-datetimepicker\",null,[[\"date\",\"format\",\"showIcon\",\"isTime\",\"disabled\",\"allowInputToggle\",\"change\"],[[25,[\"model\",\"date\"]],\"LL\",true,false,[25,[\"isDisabled\"]],true,[29,\"perform\",[[25,[\"autosave\"]]],null]]]],false],[0,\"\\n  \"],[7,\"small\"],[9],[7,\"em\"],[9],[0,\"If the round takes place across multiple days, set the round date to the last day of the round.\"],[10],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"section\"],[9],[0,\"\\n  \"],[1,[29,\"round-footnotes\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"unless\",[[29,\"eq\",[[25,[\"model\",\"kind\"]],\"Finals\"],null]],null,{\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[1,[29,\"round-spots\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/round-details.hbs"
    }
  });

  _exports.default = _default;
});