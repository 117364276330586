define("barberscore-web/controllers/dashboard/conventions/convention/sessions/session/contests/contest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortedEntriesProperties: ['name:asc'],
    sortedEntries: Ember.computed.sort('model.entries', 'sortedEntriesProperties'),
    sortedContestsProperties: ['treeSort'],
    sortedContests: Ember.computed.sort('model.session.contests', 'sortedContestsProperties')
  });

  _exports.default = _default;
});