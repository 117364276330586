define("barberscore-web/components/session-draw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    isEditing: false,
    entrySortProperties: ['draw:asc'],
    mt: Ember.computed.filterBy('model.entries', 'isMt'),
    notMt: Ember.computed.filterBy('model.entries', 'notMt'),
    filteredEntries: Ember.computed.filterBy('notMt', 'status', 'Approved'),
    sortedEntries: Ember.computed.sort('filteredEntries', 'entrySortProperties'),
    actions: {
      toggleDraw: function toggleDraw() {
        this.toggleProperty('isEditing');
      },
      reorderItems: function reorderItems(itemModels) {
        itemModels.forEach(function (item, index) {
          item.set('draw', index + 1);
        });
        itemModels.invoke('save');
        this.flashMessages.success('Success');
      }
    }
  });

  _exports.default = _default;
});