define("barberscore-web/templates/components/session-contest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6i3ASm/b",
    "block": "{\"symbols\":[\"entry\"],\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n      \"],[1,[29,\"prev-next\",null,[[\"model\",\"sortedArray\"],[[25,[\"model\"]],[25,[\"sortedContests\"]]]]],false],[0,\"\\n\\n      \"],[7,\"h4\"],[9],[0,\"\\n        \"],[1,[29,\"html-safe\",[[25,[\"model\",\"name\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"table-responsive\"],[9],[0,\"\\n        \"],[7,\"table\"],[11,\"class\",\"table table-hover table-sm\"],[9],[0,\"\\n          \"],[7,\"caption\"],[9],[0,\"Groups: \"],[1,[25,[\"model\",\"entriesCount\"]],false],[10],[0,\"\\n          \"],[7,\"thead\"],[11,\"class\",\"thead-light\"],[9],[0,\"\\n            \"],[7,\"tr\"],[9],[0,\"\\n              \"],[7,\"th\"],[9],[0,\"Name\"],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"sortedEntries\"]]],null,{\"statements\":[[0,\"              \"],[7,\"tr\"],[9],[0,\"\\n                \"],[7,\"td\"],[9],[0,\"\\n                  \"],[1,[29,\"html-safe\",[[24,1,[\"name\"]]],null],false],[0,\"\\n                \"],[10],[0,\"\\n              \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"              \"],[7,\"tr\"],[9],[0,\"\\n                \"],[7,\"td\"],[9],[0,\"(No groups entered for this contest.)\"],[10],[0,\"\\n              \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/session-contest.hbs"
    }
  });

  _exports.default = _default;
});