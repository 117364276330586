define("barberscore-web/templates/dashboard/conventions/convention/sessions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/F9owgp9",
    "block": "{\"symbols\":[\"session\"],\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"table-responsive\"],[9],[0,\"\\n    \"],[7,\"table\"],[11,\"class\",\"table table-hover table-sm\"],[9],[0,\"\\n      \"],[7,\"thead\"],[11,\"class\",\"thead-light\"],[9],[0,\"\\n        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"Kind\"],[10],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"Status\"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"sortedSessions\"]]],null,{\"statements\":[[0,\"          \"],[7,\"tr\"],[9],[0,\"\\n            \"],[7,\"td\"],[9],[0,\"\\n              \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"dashboard.conventions.convention.sessions.session.details\",[24,1,[]]]],{\"statements\":[[1,[24,1,[\"kind\"]],false]],\"parameters\":[]},null],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"td\"],[9],[0,\"\\n              \"],[1,[24,1,[\"status\"]],false],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"          \"],[7,\"tr\"],[9],[0,\"\\n            \"],[7,\"td\"],[9],[0,\"(You do not have any current assignments.  Please check with David Mills or Chris Buechler if you believe this is in error.)\"],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/dashboard/conventions/convention/sessions/index.hbs"
    }
  });

  _exports.default = _default;
});