define("barberscore-web/helpers/one-indexed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oneIndexed = oneIndexed;
  _exports.default = void 0;

  function oneIndexed(params) {
    if (params[0]) {
      return params[0] + 1;
    }
  }

  var _default = Ember.Helper.helper(oneIndexed);

  _exports.default = _default;
});