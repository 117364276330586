define("barberscore-web/transforms/appearance-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        '-30': 'Disqualified',
        '-20': 'Scratched',
        '-10': 'Completed',
        0: 'New',
        7: 'Built',
        10: 'Started',
        20: 'Finished',
        25: 'Variance',
        30: 'Verified',
        40: 'Advanced'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Disqualified': '-30',
        'Scratched': '-20',
        'Completed': '-10',
        'New': 0,
        'Built': 7,
        'Started': 10,
        'Finished': 20,
        'Variance': 25,
        'Verified': 30,
        'Advanced': 40
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});