define("barberscore-web/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S3JC59Sx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"Judges Login\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-6\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"lead\"],[9],[0,\"\\n        \"],[4,\"bs-button\",null,[[\"onClick\",\"type\",\"size\"],[[29,\"action\",[[24,0,[]],\"login\"],null],\"primary\",\"lg\"]],{\"statements\":[[0,\"Judges login with Email and Password\"]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/login.hbs"
    }
  });

  _exports.default = _default;
});