define("barberscore-web/models/chart", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: _emberData.default.attr('chart-status'),
    title: _emberData.default.attr('string'),
    arrangers: _emberData.default.attr('string'),
    composers: _emberData.default.attr('string'),
    lyricists: _emberData.default.attr('string'),
    holders: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    nomen: _emberData.default.attr('string'),
    imageId: _emberData.default.attr('string'),
    groups: _emberData.default.hasMany('group', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    // songs: DS.hasMany('song', {async: true}),
    // repertories: DS.hasMany('repertory', {async: true}),
    activate: (0, _emberApiActions.memberAction)({
      path: 'activate',
      type: 'post'
    }),
    deactivate: (0, _emberApiActions.memberAction)({
      path: 'deactivate',
      type: 'post'
    }),
    protect: (0, _emberApiActions.memberAction)({
      path: 'protect',
      type: 'post'
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    statusOptions: ['Protected', 'Inactive', 'New', 'Active'],
    statusSort: Ember.computed('status', 'statusOptions', function () {
      return this.statusOptions.indexOf(this.status);
    })
  });

  _exports.default = _default;
});