define("barberscore-web/transforms/session-kind", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        32: 'Chorus',
        41: 'Quartet',
        42: 'Mixed',
        43: 'Senior',
        44: 'Youth',
        45: 'Unknown'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Chorus': 32,
        'Quartet': 41,
        'Mixed': 42,
        'Senior': 43,
        'Youth': 44,
        'Unknown': 45
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});