define("barberscore-web/helpers/boolean-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.yesNo = yesNo;
  _exports.default = void 0;

  function yesNo(params) {
    var value = params[0];
    var out = '';

    if (value !== undefined) {
      if (value) {
        out = 'Yes';
      } else {
        out = 'No';
      }
    } else {
      out = '';
    }

    return out;
  }

  var _default = Ember.Helper.helper(yesNo);

  _exports.default = _default;
});