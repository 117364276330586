define("barberscore-web/templates/dashboard/conventions/convention/sessions/session/rounds/round/panelists/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3z8Vplct",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[1,[29,\"round-panelists\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/dashboard/conventions/convention/sessions/session/rounds/round/panelists/index.hbs"
    }
  });

  _exports.default = _default;
});