define("barberscore-web/components/user-officers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortedOfficersProperties: ['groupName'],
    filteredOfficers: Ember.computed.filterBy('model.person.officers', 'status', 'Active'),
    sortedOfficers: Ember.computed.sort('filteredOfficers', 'sortedOfficersProperties')
  });

  _exports.default = _default;
});