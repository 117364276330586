define("barberscore-web/templates/components/entry-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mfGZpELC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n  \"],[7,\"textarea\"],[12,\"value\",[25,[\"model\",\"notes\"]]],[12,\"disabled\",[23,\"isDisabled\"]],[12,\"oninput\",[29,\"perform\",[[25,[\"autosave\"]]],[[\"value\"],[\"target.value\"]]]],[11,\"class\",\"form-control\"],[11,\"rows\",\"3\"],[11,\"placeholder\",\"Enter any additional notes/requests. Indicate if any your members are also performing with other groups in this same contest session\"],[9],[0,\"  \"],[10],[0,\"\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/entry-notes.hbs"
    }
  });

  _exports.default = _default;
});