define("barberscore-web/templates/components/appearance-songs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FSYgwlXf",
    "block": "{\"symbols\":[\"song\",\"chart\"],\"statements\":[[7,\"h6\"],[9],[0,\"Songs\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"table-responsive\"],[9],[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"table table-hover table-sm\"],[9],[0,\"\\n    \"],[7,\"thead\"],[11,\"class\",\"thead-light\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Name\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"sortedSongs\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"onchange\",\"placeholder\",\"searchField\",\"allowClear\",\"disabled\"],[[25,[\"chartsList\"]],[24,1,[]],[29,\"perform\",[[25,[\"autosave\"]],[24,1,[]]],null],\"Choose Chart Title\",\"title\",false,[25,[\"isDisabled\"]]]],{\"statements\":[[0,\"              Song \"],[1,[24,1,[\"num\"]],false],[0,\": \"],[1,[24,2,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"(No songs.)\"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/appearance-songs.hbs"
    }
  });

  _exports.default = _default;
});