define("barberscore-web/components/user-assignments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeConventions: Ember.computed.filterBy('model.person.assignments', 'conventionStatus', 'Active'),
    activeAssignments: Ember.computed.filterBy('activeConventions', 'status', 'Active'),
    sortedAssignmentsProperties: ['conventionName'],
    sortedAssignments: Ember.computed.sort('activeAssignments', 'sortedAssignmentsProperties')
  });

  _exports.default = _default;
});