define("barberscore-web/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    status: _emberData.default.attr('user-status'),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean'),
    isStaff: _emberData.default.attr('boolean'),
    roles: _emberData.default.hasMany('role', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    isDisabled: Ember.computed.not('permissions.write')
  });

  _exports.default = _default;
});