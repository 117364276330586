define("barberscore-web/templates/components/panelist-scores", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sRCTmnMK",
    "block": "{\"symbols\":[\"score\"],\"statements\":[[4,\"each\",[[25,[\"sortedScores\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"input\",null,[[\"class\",\"type\",\"value\",\"min\",\"max\",\"disabled\",\"change\"],[\"form-control text-right big-num\",\"tel\",[24,1,[\"points\"]],0,100,[25,[\"isDisabled\"]],[29,\"perform\",[[25,[\"autosave\"]],[24,1,[]]],[[\"element\"],[\"target.id\"]]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/panelist-scores.hbs"
    }
  });

  _exports.default = _default;
});