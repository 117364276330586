define("barberscore-web/templates/dashboard/conventions/convention/sessions/session/rounds/round/draw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3W+PldAU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[1,[29,\"round-draw\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/dashboard/conventions/convention/sessions/session/rounds/round/draw.hbs"
    }
  });

  _exports.default = _default;
});