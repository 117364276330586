define("barberscore-web/helpers/member-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.customerService = customerService;
  _exports.default = void 0;

  function customerService() {
    return Ember.String.htmlSafe("<a href='https://members.barbershop.org'>Member Center</a>");
  }

  var _default = Ember.Helper.helper(customerService);

  _exports.default = _default;
});