define("barberscore-web/helpers/html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.htmlString = htmlString;
  _exports.default = void 0;

  function htmlString(params) {
    return Ember.String.htmlSafe(params[0]);
  }

  var _default = Ember.Helper.helper(htmlString);

  _exports.default = _default;
});