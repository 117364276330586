define("barberscore-web/models/appearance", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  var _default = _model.default.extend({
    status: _emberData.default.attr('appearance-status'),
    num: _emberData.default.attr('number'),
    draw: _emberData.default.attr('number'),
    isPrivate: _emberData.default.attr('boolean'),
    isSingle: _emberData.default.attr('boolean'),
    participants: _emberData.default.attr('string'),
    area: _emberData.default.attr('string'),
    onstage: _emberData.default.attr('date'),
    actualStart: _emberData.default.attr('date'),
    actualFinish: _emberData.default.attr('date'),
    pos: _emberData.default.attr('number'),
    stats: _emberData.default.attr(),
    base: _emberData.default.attr('number'),
    diff: _emberData.default.attr('number'),
    varianceReport: _emberData.default.attr('string'),
    csaReport: _emberData.default.attr('string'),
    groupId: _emberData.default.attr('string'),
    entryId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    kind: _emberData.default.attr('group-kind'),
    gender: _emberData.default.attr('group-gender'),
    district: _emberData.default.attr('group-district'),
    division: _emberData.default.attr('group-division'),
    bhsId: _emberData.default.attr('number'),
    code: _emberData.default.attr('string'),
    imageId: _emberData.default.attr('string', {
      defaultValue: 'missing_image'
    }),
    charts: _emberData.default.attr(),
    round: _emberData.default.belongsTo('round', {
      async: true
    }),
    songs: _emberData.default.hasMany('song', {
      async: true
    }),
    outcomes: _emberData.default.hasMany('outcome', {
      async: true
    }),
    owners: _emberData.default.hasMany('user', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    start: (0, _emberApiActions.memberAction)({
      path: 'start',
      type: 'post'
    }),
    finish: (0, _emberApiActions.memberAction)({
      path: 'finish',
      type: 'post'
    }),
    verify: (0, _emberApiActions.memberAction)({
      path: 'verify',
      type: 'post'
    }),
    complete: (0, _emberApiActions.memberAction)({
      path: 'complete',
      type: 'post'
    }),
    advance: (0, _emberApiActions.memberAction)({
      path: 'advance',
      type: 'post'
    }),
    scratch: (0, _emberApiActions.memberAction)({
      path: 'scratch',
      type: 'post'
    }),
    disqualify: (0, _emberApiActions.memberAction)({
      path: 'disqualify',
      type: 'post'
    }),
    mock: (0, _emberApiActions.memberAction)({
      path: 'mock',
      type: 'get'
    }),
    variance: (0, _emberApiActions.memberAction)({
      path: 'variance',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    csa: (0, _emberApiActions.memberAction)({
      path: 'csa',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    isNumber: Ember.computed.notEmpty('draw'),
    isGt: Ember.computed.gt('draw', 0),
    isDrawn: Ember.computed.and('isNumber', 'isGt'),
    isVerified: Ember.computed.equal('status', 'Verified'),
    statusOptions: ['New', 'Validated', 'Started', 'Finished', 'Confirmed', 'Included', 'Excluded', 'Announced'],
    isMulti: Ember.computed.not('isSingle'),
    isMT: Ember.computed.equal('num', 0),
    notMT: Ember.computed.gt('num', 0),
    runTotSum: Ember.computed.alias('stats.tot_points'),
    runSngSum: Ember.computed.alias('stats.sng_points'),
    runPerSum: Ember.computed.alias('stats.per_points'),
    runTotAvg: Ember.computed.alias('stats.tot_score'),
    roundNum: Ember.computed.alias('round.num'),
    conventionName: Ember.computed.alias('round.session.convention.nomen'),
    sessionKind: Ember.computed.alias('round.session.kind'),
    groupName: Ember.computed.alias('group.name'),
    isAdvancer: Ember.computed.gt('draw', 0),
    isFinisher: Ember.computed.lt('draw', 0),
    repertoriesFiltered: Ember.computed.alias('group.repertories'),
    chartsMapped: Ember.computed.mapBy('repertoriesFiltered', 'chart'),
    chartOptionsProperties: ['title'],
    chartOptions: Ember.computed.sort('chartsMapped', 'chartOptionsProperties'),
    practiceSongScores: Ember.computed.mapBy('songs', 'sumPracticeScores'),
    sumPractice: Ember.computed.sum('practiceSongScores'),
    officialSongScores: Ember.computed.mapBy('songs', 'sumOfficialScores'),
    sumOfficial: Ember.computed.sum('officialSongScores') // entry: DS.belongsTo('entry', {async: true}),

  });

  _exports.default = _default;
});