define("barberscore-web/templates/application-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lf94nmlR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[9],[0,\"\\n  Encountered an error: \"],[1,[25,[\"model\",\"error\"]],false],[0,\" -- \"],[1,[25,[\"model\",\"errorDescription\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"\\n  For assistance, please send this message to admin@barberscore.com.  Thank you!\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/application-error.hbs"
    }
  });

  _exports.default = _default;
});