define("barberscore-web/routes/dashboard/conventions/convention/sessions/session/assignments/assignment", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(_ref) {
      var assignment_id = _ref.assignment_id;
      return this.store.findRecord('assignment', assignment_id, {});
    }
  });

  _exports.default = _default;
});