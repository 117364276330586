define("barberscore-web/controllers/dashboard/conventions/convention/sessions/session/assignments/assignment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortedAssignmentsProperties: ['kindSort', 'categorySort', 'lastName', 'firstName'],
    sortedAssignments: Ember.computed.sort('model.session.assignments', 'sortedAssignmentsProperties')
  });

  _exports.default = _default;
});