define("barberscore-web/components/session-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    newEntries: Ember.computed.filterBy('model.entries', 'status', 'New'),
    builtEntries: Ember.computed.filterBy('model.entries', 'status', 'Built'),
    invitedEntries: Ember.computed.filterBy('model.entries', 'status', 'Invited'),
    withdrawnEntries: Ember.computed.filterBy('model.entries', 'status', 'Withdrawn'),
    submittedEntries: Ember.computed.filterBy('model.entries', 'status', 'Submitted'),
    approvedEntries: Ember.computed.filterBy('model.entries', 'status', 'Approved'),
    newEntriesCount: Ember.computed.alias('newEntries.length'),
    builtEntriesCount: Ember.computed.alias('builtEntries.length'),
    invitedEntriesCount: Ember.computed.alias('invitedEntries.length'),
    withdrawnEntriesCount: Ember.computed.alias('withdrawnEntries.length'),
    submittedEntriesCount: Ember.computed.alias('submittedEntries.length'),
    approvedEntriesCount: Ember.computed.alias('approvedEntries.length'),
    totalEntriesCount: Ember.computed.alias('model.entries.length'),
    contestCount: Ember.computed.alias('model.contests.length')
  });

  _exports.default = _default;
});