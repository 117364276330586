define("barberscore-web/templates/dashboard/groups/group/charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QBq+iCST",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"lead\"],[9],[0,\"\\n      Here is where you enter your group's repertory.  Any song you plan to perform at contest must be listed here prior to your performance.\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[1,[29,\"group-charts\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/dashboard/groups/group/charts.hbs"
    }
  });

  _exports.default = _default;
});