define("barberscore-web/models/statelog", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    timestamp: _emberData.default.attr('date'),
    transition: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    by: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});