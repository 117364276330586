define("barberscore-web/components/cloudinary-image", ["exports", "ember-cli-cloudinary-light/components/cloudinary-image"], function (_exports, _cloudinaryImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _cloudinaryImage.default;
    }
  });
});