define("barberscore-web/models/group", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: _emberData.default.attr('string'),
    status: _emberData.default.attr('group-status'),
    kind: _emberData.default.attr('group-kind'),
    gender: _emberData.default.attr('group-gender'),
    district: _emberData.default.attr('group-district'),
    division: _emberData.default.attr('group-division'),
    bhsId: _emberData.default.attr('number'),
    code: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    // participants: DS.attr('string'),
    // chapters: DS.attr('string'),
    // pos: DS.attr('number'),
    isSenior: _emberData.default.attr('boolean'),
    isYouth: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    sourceId: _emberData.default.attr('string'),
    nomen: _emberData.default.attr('string'),
    imageId: _emberData.default.attr('string'),
    owners: _emberData.default.hasMany('user', {
      async: true
    }),
    charts: _emberData.default.hasMany('chart', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    activate: (0, _emberApiActions.memberAction)({
      path: 'activate',
      type: 'post'
    }),
    deactivate: (0, _emberApiActions.memberAction)({
      path: 'deactivate',
      type: 'post'
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    // repertoriesCount: alias('repertories.length'),
    activeMembers: Ember.computed.filterBy('members', 'status', 'Active'),
    // activesCount: alias('activeMembers.length'),
    isFemale: Ember.computed.equal('gender', 'Female'),
    isMixed: Ember.computed.equal('gender', 'Mixed'),
    genderOptions: ['Male', 'Female', 'Mixed'],
    genderSort: Ember.computed('gender', 'genderOptions', function () {
      return this.genderOptions.indexOf(this.gender);
    }),
    statusOptions: ['New', 'Active', 'Inactive'],
    statusSort: Ember.computed('status', 'statusOptions', function () {
      return this.statusOptions.indexOf(this.status);
    }),
    kindOptions: ['International', 'District', 'Noncompetitive', 'Affiliate', 'Chapter', 'Chorus', 'Quartet'],
    kindSort: Ember.computed('kind', 'kindOptions', function () {
      return this.kindOptions.indexOf(this.kind);
    })
  });

  _exports.default = _default;
});