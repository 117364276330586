define("barberscore-web/templates/components/appearance-verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yKMxjM+n",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-button\",null,[[\"type\",\"disabled\",\"onClick\"],[\"primary\",[25,[\"model\",\"isDisabled\"]],[29,\"perform\",[[25,[\"verifyAppearance\"]]],null]]],{\"statements\":[[4,\"if\",[[25,[\"verifyAppearance\",\"isRunning\"]]],null,{\"statements\":[[0,\"    Checking... \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"model\",\"status\"]],\"Finished\"],null]],null,{\"statements\":[[0,\"      Check Appearance\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"model\",\"status\"]],\"Variance\"],null]],null,{\"statements\":[[0,\"      Verify Appearance\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/appearance-verify.hbs"
    }
  });

  _exports.default = _default;
});