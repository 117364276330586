define("barberscore-web/helpers/is-scjc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isScjc = isScjc;
  _exports.default = void 0;

  function isScjc(params) {
    if (params[0].includes('BS Admin')) {
      return true;
    }

    return false;
  }

  var _default = Ember.Helper.helper(isScjc);

  _exports.default = _default;
});