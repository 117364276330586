define("barberscore-web/components/member-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortProperties: ['groupKind', 'groupName'],
    collapsedNote: true,
    sortedMembers: Ember.computed.sort('model', 'sortProperties')
  });

  _exports.default = _default;
});