define("barberscore-web/instance-initializers/algolia-config", ["exports", "barberscore-web/config/environment", "npm:algoliasearch"], function (_exports, _environment, _npmAlgoliasearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var service = appInstance.lookup('service:algolia');
    var algoliaConfig = _environment.default['ember-algolia'];
    Ember.set(service, 'client', (0, _npmAlgoliasearch.default)(algoliaConfig.algoliaId, algoliaConfig.algoliaKey));
  }

  var _default = {
    name: 'algolia-config',
    initialize: initialize
  };
  _exports.default = _default;
});