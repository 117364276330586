define("barberscore-web/transforms/round-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        0: 'New',
        10: 'Built',
        20: 'Started',
        25: 'Completed',
        27: 'Finalized',
        30: 'Published'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'New': 0,
        'Built': 10,
        'Started': 20,
        'Completed': 25,
        'Finalized': 27,
        'Published': 30
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});