define("barberscore-web/templates/dashboard/conventions/convention/sessions/session/assignments/assignment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2aRKgck9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n        \"],[1,[29,\"prev-next\",null,[[\"model\",\"sortedArray\"],[[25,[\"model\"]],[25,[\"sortedAssignments\"]]]]],false],[0,\"\\n      \"],[7,\"h4\"],[9],[0,\"\\n        \"],[1,[25,[\"model\",\"name\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n      \"],[7,\"section\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n          \"],[1,[29,\"cloudinary-image\",[[25,[\"model\",\"imageId\"]],[29,\"hash\",null,[[\"width\"],[64]]]],[[\"class\"],[\"img-fluid img-rounded\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"h4\"],[9],[0,\"\\n          \"],[1,[25,[\"model\",\"kind\"]],false],[0,\" \"],[1,[25,[\"model\",\"category\"]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-footer\"],[9],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"list-inline\"],[9],[0,\"\\n        \"],[7,\"li\"],[11,\"class\",\"list-inline-item\"],[9],[1,[29,\"assignment-delete\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/dashboard/conventions/convention/sessions/session/assignments/assignment.hbs"
    }
  });

  _exports.default = _default;
});