define("barberscore-web/models/outcome", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: _emberData.default.attr('outcome-status'),
    num: _emberData.default.attr('number'),
    winner: _emberData.default.attr('string'),
    awardId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    kind: _emberData.default.attr('award-kind'),
    gender: _emberData.default.attr('award-gender'),
    level: _emberData.default.attr('award-level'),
    season: _emberData.default.attr('award-season'),
    description: _emberData.default.attr('string'),
    district: _emberData.default.attr('award-district'),
    division: _emberData.default.attr('award-division'),
    age: _emberData.default.attr('award-age'),
    isNovice: _emberData.default.attr('boolean'),
    isSingle: _emberData.default.attr('boolean'),
    size: _emberData.default.attr('string'),
    sizeRange: _emberData.default.attr('string'),
    scope: _emberData.default.attr('string'),
    scopeRange: _emberData.default.attr('string'),
    treeSort: _emberData.default.attr('string'),
    printed: _emberData.default.attr('boolean'),
    printOnFinalsOss: _emberData.default.attr('boolean'),
    round: _emberData.default.belongsTo('round', {
      async: true
    }),
    appearances: _emberData.default.hasMany('appearance', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    isFinals: Ember.computed('round', function () {
      if (this.round.get('kind') == 'Finals') {
        return true;
      }

      return false;
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    disablePrinted: Ember.computed('round', function () {
      if (this.round.get('status') == 'Published') {
        return true;
      }

      return this.get('printOnFinalsOss');
    }),
    order: Ember.computed('num', 'treeSort', function () {
      if (this.treeSort) {
        return this.treeSort;
      } else {
        return this.num;
      }
    })
  });

  _exports.default = _default;
});