define("barberscore-web/components/appearance-panelists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isDisabled: Ember.computed('model.round.status', function () {
      if (this.get('model.round.status') == 'Published') {
        return true;
      }

      return false;
    }),
    sortedPanelistsProperties: ['kindSort', 'categorySort', 'num'],
    filteredPanelists: Ember.computed.filterBy('model.round.panelists', 'isScoring'),
    sortedPanelists: Ember.computed.sort('filteredPanelists', 'sortedPanelistsProperties')
  });

  _exports.default = _default;
});