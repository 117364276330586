define("barberscore-web/helpers/env", ["exports", "barberscore-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //app/helpers/env.js
  var _default = Ember.Helper.helper(function env() {
    if (_environment.default.APP.ENVIRONMENT_NAME !== 'production') {
      return _environment.default.APP.ENVIRONMENT_NAME;
    }
  });

  _exports.default = _default;
});