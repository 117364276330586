define("barberscore-web/models/round", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: _emberData.default.attr('round-status'),
    kind: _emberData.default.attr('round-kind'),
    num: _emberData.default.attr('number'),
    spots: _emberData.default.attr('number'),
    date: _emberData.default.attr('isodate'),
    footnotes: _emberData.default.attr('string'),
    ossReport: _emberData.default.attr('string'),
    saReport: _emberData.default.attr('string'),
    legacyOss: _emberData.default.attr('string'),
    isReviewed: _emberData.default.attr('boolean'),
    conventionId: _emberData.default.attr('string'),
    nomen: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string'),
    imageId: _emberData.default.attr('string'),
    sessionId: _emberData.default.attr('string'),
    sessionKind: _emberData.default.attr('session-kind'),
    baseFilename: _emberData.default.attr('string'),
    scoresheetFilename: _emberData.default.attr('string'),
    revisionNumber: _emberData.default.attr('number'),
    revisionDate: _emberData.default.attr('string'),
    revisionNomen: _emberData.default.attr('string'),
    owners: _emberData.default.hasMany('user', {
      async: true
    }),
    appearances: _emberData.default.hasMany('appearance', {
      async: true
    }),
    panelists: _emberData.default.hasMany('panelist', {
      async: true
    }),
    outcomes: _emberData.default.hasMany('outcome', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    reset: (0, _emberApiActions.memberAction)({
      path: 'reset',
      type: 'post'
    }),
    build: (0, _emberApiActions.memberAction)({
      path: 'build',
      type: 'post'
    }),
    start: (0, _emberApiActions.memberAction)({
      path: 'start',
      type: 'post'
    }),
    complete: (0, _emberApiActions.memberAction)({
      path: 'complete',
      type: 'post'
    }),
    finalize: (0, _emberApiActions.memberAction)({
      path: 'finalize',
      type: 'post'
    }),
    publish: (0, _emberApiActions.memberAction)({
      path: 'publish',
      type: 'post'
    }),
    labels: (0, _emberApiActions.memberAction)({
      path: 'labels',
      type: 'post',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    mock: (0, _emberApiActions.memberAction)({
      path: 'mock',
      type: 'get'
    }),
    oss: (0, _emberApiActions.memberAction)({
      path: 'oss',
      type: 'post',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    sa: (0, _emberApiActions.memberAction)({
      path: 'sa',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    legacyoss: (0, _emberApiActions.memberAction)({
      path: 'legacyoss',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    titles: (0, _emberApiActions.memberAction)({
      path: 'titles',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    announcements: (0, _emberApiActions.memberAction)({
      path: 'announcements',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    isFinals: Ember.computed('round', function () {
      if (this.get('kind') == 'Finals') {
        return true;
      }

      return false;
    }),
    statusOptions: ['New', 'Drawn', 'Validated', 'Started', 'Completed', 'Announced'],
    kindOptions: ['Finals', 'Semi-Finals', 'Quarter-Finals'],
    kindSort: Ember.computed('kind', 'kindOptions', function () {
      return this.kindOptions.indexOf(this.kind);
    }),
    statusSort: Ember.computed('status', 'statusOptions', function () {
      return this.statusOptions.indexOf(this.status);
    })
  });

  _exports.default = _default;
});