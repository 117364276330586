define("barberscore-web/transforms/participant-part", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        '-1': 'Director',
        1: 'Tenor',
        2: 'Lead',
        3: 'Baritone',
        4: 'Bass'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Director': '-1',
        'Tenor': 1,
        'Lead': 2,
        'Baritone': 3,
        'Bass': 4
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});