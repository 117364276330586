define("barberscore-web/templates/components/round-spots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IXszmd/M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n  Spots Available in Next Round (blank for unlimited):\\n  \"],[7,\"input\"],[12,\"value\",[25,[\"model\",\"spots\"]]],[12,\"disabled\",[23,\"isDisabled\"]],[12,\"oninput\",[29,\"perform\",[[25,[\"autosave\"]]],[[\"value\"],[\"target.value\"]]]],[11,\"class\",\"form-control col-sm-1\"],[11,\"type\",\"number\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/components/round-spots.hbs"
    }
  });

  _exports.default = _default;
});