define("barberscore-web/models/assignment", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: _emberData.default.attr('assignment-status'),
    kind: _emberData.default.attr('assignment-kind'),
    category: _emberData.default.attr('assignment-category'),
    personId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    lastName: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    display_district: _emberData.default.attr('assignment-district'),
    district: _emberData.default.attr('assignment-district'),
    area: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    cellPhone: _emberData.default.attr('string'),
    airports: _emberData.default.attr(),
    bhsId: _emberData.default.attr('number'),
    imageId: _emberData.default.attr('string'),
    session: _emberData.default.belongsTo('session', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    activate: (0, _emberApiActions.memberAction)({
      path: 'activate',
      type: 'post'
    }),
    deactivate: (0, _emberApiActions.memberAction)({
      path: 'deactivate',
      type: 'post'
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    categorySort: Ember.computed('category', 'categoryOptions', function () {
      return this.categoryOptions.indexOf(this.category);
    }),
    kindSort: Ember.computed('kind', 'kindOptions', function () {
      return this.kindOptions.indexOf(this.kind);
    }),
    statusOptions: ['New', 'Active', 'Inactive'],
    categoryOptions: ['DRCJ', 'PC', 'ADM', 'Music', 'Performance', 'Singing'],
    kindOptions: ['Official', 'Practice', 'Observer', 'Former']
  });

  _exports.default = _default;
});