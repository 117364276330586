define("barberscore-web/sentry", ["exports", "@sentry/browser", "@sentry/integrations", "barberscore-web/config/environment"], function (_exports, Sentry, Integrations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Sentry.init({
    dsn: _environment.default.APP.SENTRY_DSN,
    environment: _environment.default.APP.HEROKU_APP_NAME,
    sendDefaultPii: true,
    integrations: [new Integrations.Ember()],
    beforeSend: function beforeSend(event, hint) {
      if (_environment.default.environment !== 'production' || hint.originalException.name === 'TransitionAborted') {
        // eslint-disable-next-line
        console.error(hint.originalException || hint.syntheticException);
        return null; // this drops the event and nothing will be send to sentry
      }

      return event;
    }
  });
  var _default = Sentry;
  _exports.default = _default;
});