define("barberscore-web/controllers/dashboard/conventions/convention/sessions/session/contests/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortedContestsProperties: ['treeSort'],
    sortedContests: Ember.computed.sort('model.contests', 'sortedContestsProperties')
  });

  _exports.default = _default;
});