define("barberscore-web/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u1xdk61R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[9],[0,\"\\n  I'm sorry, we couldn't find that page!\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/404.hbs"
    }
  });

  _exports.default = _default;
});