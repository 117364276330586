define("barberscore-web/transforms/award-level", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        10: 'Championship',
        30: 'Qualifier',
        45: 'Representative',
        50: 'Deferred',
        60: 'Manual',
        70: 'Improved - Raw',
        80: 'Improved - Standard'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      var map = {
        'Championship': 10,
        'Qualifier': 30,
        'Representative': 45,
        'Deferred': 50,
        'Manual': 60,
        'Improved - Raw': 70,
        'Improved - Standard': 80
      };
      return map[deserialized];
    }
  });

  _exports.default = _default;
});