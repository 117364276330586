define("barberscore-web/models/session", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    nomen: _emberData.default.attr('string'),
    status: _emberData.default.attr('session-status'),
    kind: _emberData.default.attr('session-kind'),
    numRounds: _emberData.default.attr('number'),
    isInvitational: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    footnotes: _emberData.default.attr('string'),
    legacyReport: _emberData.default.attr('string'),
    drcjReport: _emberData.default.attr('string'),
    convention_id: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    district: _emberData.default.attr('session-district'),
    season: _emberData.default.attr('string'),
    panel: _emberData.default.attr('string'),
    year: _emberData.default.attr('string'),
    openDate: _emberData.default.attr('date-only'),
    closeDate: _emberData.default.attr('date-only'),
    startDate: _emberData.default.attr('date-only'),
    endDate: _emberData.default.attr('date-only'),
    venueName: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string'),
    divisions: _emberData.default.attr(),
    imageId: _emberData.default.attr('string'),
    roundsPublished: _emberData.default.attr('boolean'),
    owners: _emberData.default.hasMany('user', {
      async: true
    }),
    contests: _emberData.default.hasMany('contest', {
      async: true
    }),
    entries: _emberData.default.hasMany('entry', {
      async: true
    }),
    assignments: _emberData.default.hasMany('assignment', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    reset: (0, _emberApiActions.memberAction)({
      path: 'reset',
      type: 'post'
    }),
    build: (0, _emberApiActions.memberAction)({
      path: 'build',
      type: 'post'
    }),
    open: (0, _emberApiActions.memberAction)({
      path: 'open',
      type: 'post'
    }),
    close: (0, _emberApiActions.memberAction)({
      path: 'close',
      type: 'post'
    }),
    verify: (0, _emberApiActions.memberAction)({
      path: 'verify',
      type: 'post'
    }),
    package: (0, _emberApiActions.memberAction)({
      path: 'package',
      type: 'post'
    }),
    finish: (0, _emberApiActions.memberAction)({
      path: 'finish',
      type: 'post'
    }),
    legacy: (0, _emberApiActions.memberAction)({
      path: 'legacy',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    drcj: (0, _emberApiActions.memberAction)({
      path: 'drcj',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    notArchived: Ember.computed.not('isArchived'),
    statusOptions: ['New', 'Built', 'Opened', 'Closed', 'Verified', 'Packaged', 'Finished'],
    kindOptions: ['Quartet', 'Chorus', 'Mixed', 'Senior', 'Youth', 'Unknown', 'VLQ'],
    numOptions: [1, 2, 3],
    statusSort: Ember.computed('status', 'statusOptions', function () {
      return this.statusOptions.indexOf(this.status);
    }),
    kindSort: Ember.computed('kind', 'kindOptions', function () {
      return this.kindOptions.indexOf(this.kind);
    }),
    currentAppearancesSort: ['num'],
    currentAppearances: Ember.computed.sort('current.appearances', 'currentAppearancesSort')
  });

  _exports.default = _default;
});