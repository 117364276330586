define("barberscore-web/adapters/user", ["exports", "ember-data", "barberscore-web/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "ember-cli-file-saver/mixins/adapter-arraybuffer-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin, _adapterArraybufferMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_adapterArraybufferMixin.default, _dataAdapterMixin.default, {
    host: _environment.default.APP.API_HOST,
    namespace: 'jwt',
    coalesceFindRequests: false,
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          idToken = _this$get.idToken;

      if (Ember.isPresent(idToken)) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(idToken));
      }
    }
  });

  _exports.default = _default;
});