define("barberscore-web/components/person-subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    collapsedNote: true,
    sortedGroupsProperties: ['treeSort', 'name'],
    sortedGroups: Ember.computed.sort('model', 'sortedGroupsProperties')
  });

  _exports.default = _default;
});