define("barberscore-web/templates/dashboard/conventions/convention/sessions/session/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nu7jacBi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"or\",[[29,\"eq\",[[25,[\"model\",\"status\"]],\"Verified\"],null],[29,\"eq\",[[25,[\"model\",\"status\"]],\"Packaged\"],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[11,\"class\",\"list-unstyled\"],[9],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"double\"],[9],[1,[29,\"download-drcj\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[10],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"double\"],[9],[1,[29,\"download-legacy\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"\\n      DRCJ and Legacy Reports are available after the Session is Verified.\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/dashboard/conventions/convention/sessions/session/reports.hbs"
    }
  });

  _exports.default = _default;
});