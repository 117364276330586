define("barberscore-web/helpers/capitalize-word", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalizeWord = capitalizeWord;
  _exports.default = void 0;

  function capitalizeWord(params) {
    if (params[0]) {
      return params[0].capitalize();
    }
  }

  var _default = Ember.Helper.helper(capitalizeWord);

  _exports.default = _default;
});