define("barberscore-web/components/user-members", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortedMembersProperties: ['groupKind', 'groupName'],
    activeGroups: Ember.computed.filterBy('model.person.members', 'groupStatus', 'Active'),
    activeMembers: Ember.computed.filterBy('activeGroups', 'status', 'Active'),
    sortedMembers: Ember.computed.sort('activeMembers', 'sortedMembersProperties')
  });

  _exports.default = _default;
});