define("barberscore-web/services/current-user", ["exports", "barberscore-web/sentry"], function (_exports, _sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    load: function load() {
      var _this = this;

      var id = this.get('session.data.authenticated.profile.sub');

      if (!Ember.isEmpty(id)) {
        return this.store.findRecord('user', id).then(function (user) {
          return user;
        }).catch(function (err) {
          alert(err.errors[0].detail);
          return Ember.RSVP.resolve();
        }).then(function (user) {
          _sentry.default.configureScope(function (scope) {
            scope.setUser({
              id: user.id,
              name: user.name,
              username: user.username,
              email: user.email
            });
          });

          return _this.set('user', user);
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });

  _exports.default = _default;
});