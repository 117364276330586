define("barberscore-web/transforms/organization-kind", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.groupKindMap = void 0;
  var groupKindMap = {
    'International': 1,
    'District': 11,
    'Noncompetitive': 12,
    'Affiliate': 13,
    'Chapter': 30,
    'Chorus': 32,
    'Quartet': 41
  };
  _exports.groupKindMap = groupKindMap;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var map = {
        1: 'International',
        11: 'District',
        12: 'Noncompetitive',
        13: 'Affiliate',
        30: 'Chapter',
        32: 'Chorus',
        41: 'Quartet'
      };
      return map[serialized];
    },
    serialize: function serialize(deserialized) {
      return groupKindMap[deserialized];
    }
  });

  _exports.default = _default;
});