define("barberscore-web/transforms/date-range", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      try {
        if (value.get('lower') != null && value.get('upper') != null) {
          return {
            lower: value.get('lower'),
            upper: value.get('upper'),
            bounds: "[)"
          };
        } else if (value.get('lower') != null && value.get('upper') == null) {
          return {
            lower: value.get('lower'),
            upper: null,
            bounds: "[)"
          };
        } else {
          return {};
        }
      } catch (err) {
        return {};
      }
    },
    deserialize: function deserialize(value) {
      if (value) {
        return Ember.Object.create({
          lower: value['lower'],
          upper: value['upper']
        });
      } else {
        return null;
      }
    }
  });

  _exports.default = _default;
});