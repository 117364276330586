define("barberscore-web/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IXhQSGsx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"jumbotron \"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"lead\"],[9],[0,\"\\n    Barberscore is the Contest Manager for the Barbershop Harmony Society.\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"Contest entry and song repertory management will soon be managed via the \"],[7,\"a\"],[11,\"href\",\"https://members.barbershop.org\"],[9],[0,\"Member Center\"],[10],[0,\".\"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "barberscore-web/templates/index.hbs"
    }
  });

  _exports.default = _default;
});