define("barberscore-web/models/convention", ["exports", "ember-data/model", "ember-data", "ember-api-actions"], function (_exports, _model, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    nomen: _emberData.default.attr('string'),
    status: _emberData.default.attr('convention-status'),
    name: _emberData.default.attr('string'),
    district: _emberData.default.attr('convention-district'),
    season: _emberData.default.attr('convention-season'),
    panel: _emberData.default.attr('convention-panel'),
    year: _emberData.default.attr('number', {
      defaultValue: 2019
    }),
    openDate: _emberData.default.attr('isodate'),
    closeDate: _emberData.default.attr('isodate'),
    startDate: _emberData.default.attr('isodate'),
    endDate: _emberData.default.attr('isodate'),
    venueName: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    location: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    timezone: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    divisions: _emberData.default.attr(),
    kinds: _emberData.default.attr(),
    roundsFinalized: _emberData.default.attr('boolean'),
    baseFilename: _emberData.default.attr('string'),
    imageId: _emberData.default.attr('string'),
    persons: _emberData.default.hasMany('person', {
      async: true
    }),
    owners: _emberData.default.hasMany('user', {
      async: true
    }),
    permissions: _emberData.default.attr(),
    reset: (0, _emberApiActions.memberAction)({
      path: 'reset',
      type: 'post'
    }),
    build: (0, _emberApiActions.memberAction)({
      path: 'build',
      type: 'post'
    }),
    activate: (0, _emberApiActions.memberAction)({
      path: 'activate',
      type: 'post'
    }),
    deactivate: (0, _emberApiActions.memberAction)({
      path: 'deactivate',
      type: 'post'
    }),
    bbstix: (0, _emberApiActions.memberAction)({
      path: 'bbstix',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    bbstixPractice: (0, _emberApiActions.memberAction)({
      path: 'bbstix_practice',
      type: 'get',
      ajaxOptions: {
        arraybuffer: true
      }
    }),
    isDisabled: Ember.computed.not('permissions.write'),
    statusOptions: ['New', 'Built', 'Active', 'Inactive', 'Cancelled'],
    seasonOptions: ['Fall', 'Spring'],
    panelOptions: ['Single', 'Double', 'Triple', 'Quadruple', 'Quintiple']
  });

  _exports.default = _default;
});